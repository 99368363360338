<template>
  <v-container
      id="dashboard"
      fluid
      tag="section"
  >
    <template v-if="!blank && (!environments || environments.length === 0)">
      <v-progress-linear
          indeterminate
          color="primary"
      ></v-progress-linear>
      <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
    </template>
    <v-row v-else-if="blank && (!environments || environments.length === 0)">
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <h1 class="card-title">Welcome to M360API Console</h1>
          </template>
          <v-row no-gutters class="mt-10" id="welcome-blank-env">
            <v-col cols="12" class="pl-5">
              <p>
                M360API Console is the interface that engineers use to configure, monitor, and operate on application
                deployed in all the environments of a project.
              </p>
              <p>
                The Console is multi-environment by design, from the same interface, you can access and operate
                all the environments in the same project.
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pl-5" cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <p>
                The Console is equipped with tons of wizards allowing you to:
              </p>
              <v-list flat class="my-5">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="x-large" color="info">mdi-cogs</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Create multiple environments and customize their registries and settings
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="x-large" color="primary">mdi-server</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Connect resources from different technologies, hosting platforms, and different cloud providers
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="x-large" color="success">mdi-apps</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Create and configure Multi-tenant applications and share them between environments
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="x-large" color="error">mdi-api</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Deploy, Monitor, Operate, and troubleshoot M360API Gateway and other running components
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon size="x-large" color="warning">mdi-domain</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Manage your Organization Chart, Access Roles, Users and Accounts Information.
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!--            <p>These wizards also guide engineers in defining who has access to their deployed components, monitor, and troubleshoot running systems. Furthermore, the console is equipped with an API Builder, that helps engineers generate microservices in any web programming language that are preconfigured with the M360API Middleware and ready to be deployed behind the API Gateway.-->
              <!--            </p>-->
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" class="text-center">
              <v-img
                  class="mx-auto"
                  max-width="80%"
                  :lazy-src="require('@/assets/DevOps-Best-Practice_1080.png')"
                  :src="require('@/assets/DevOps-Best-Practice_1080.png')"
              ></v-img>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mt-10 mb-5 text-center">
              <v-btn
                  tile
                  color="primary"
                  v-show="canAccess({method: 'put', route: '/environments'})"
                  @click="goToPage({route: 'createEnvironment'});"
              >
                <v-icon left>mdi-plus</v-icon>
                Create Your First Environment
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="10" xs="0" sm="0" md="10" id="dashboard-card"></v-col>
      <v-col cols="2" xs="12" sm="12" md="2" class="text-right">
        <v-card class="ma-0">
          <v-card-text class="py-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                    v-on="on"
                    v-bind="attrs"
                    :items="timeIntervals"
                    item-value="id"
                    item-text="label"
                    :placeholder="`( ${defaultInterval} seconds )`"
                    color="purple"
                    prepend-icon="mdi-refresh"
                    @change="siwtchInterval"
                ></v-select>
              </template>
              <span>Change Refresh Interval</span>
            </v-tooltip>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          v-for="oneEnv in environments"
          :key="oneEnv.code"
          cols="12"
          lg="4"
          md="6"
      >

        <base-material-card
            hover-reveal
            :color="oneEnv.color.code"
            class="v-card--material-chart"
        >
          <template v-slot:heading>
            <h2 class="card-title ml-2">{{ oneEnv.code }}</h2>
          </template>

          <template v-slot:reveal-actions>
            <p class="d-inline-flex font-weight-light mt-5">{{ oneEnv.description }}</p>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                    v-bind="attrs"
                    light
                    icon
                    v-on="on"
                    color="green"
                    class="no-bgBtn"
                    @click="switchAndGo(oneEnv.code, `/settings/${oneEnv.code}`, $event)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Modify Environment</span>
            </v-tooltip>
          </template>

          <v-row class="mt-5 mb-5 mr-1 ml-1" v-if="!loading && oneEnv && oneEnv.code && stats && stats[oneEnv.code]">

            <v-col class="pa-0 mb-3" cols="6" md="6" lg="6" xl="3" sm="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="secondary lighten-1 white--text"
                      class="white--text"
                      :style="{'width': '90%'}"
                      @click="switchAndGo(oneEnv.code, `/resources/${oneEnv.code}`, $event)"
                  >
                    <v-icon
                        left
                        dark
                    >
                      mdi-server
                    </v-icon>
                    {{ stats[ oneEnv.code ].resources }} Cluster{{ stats[ oneEnv.code ].resources > 1 ? 's' : '' }}
                  </v-btn>
                </template>
                <span>Open Data Clusters in {{ oneEnv.code.toUpperCase() }} Environment</span>
              </v-tooltip>
            </v-col>

            <v-col class="pa-0 mb-3" cols="6" md="6" lg="6" xl="3" sm="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="green lighten-1 white--text"
                      class="white--text"
                      :style="{'width': '90%'}"
                      @click="switchAndGo(oneEnv.code, `/resources/${oneEnv.code}`, $event)"
                  >
                    <v-icon
                        left
                        dark
                    >
                      mdi-database
                    </v-icon>
                    {{ stats[ oneEnv.code ].databases }} Database{{ stats[ oneEnv.code ].databases > 1 ? 's' : '' }}
                  </v-btn>
                </template>
                <span>Open Databases in {{ oneEnv.code.toUpperCase() }} Environment</span>
              </v-tooltip>

            </v-col>

            <v-col class="pa-0 mb-3" cols="6" md="6" lg="6" xl="3" sm="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="warning lighten-1 white--text"
                      class="white--text"
                      :style="{'width': '90%'}"
                      @click="switchAndGo(oneEnv.code, `/settings/${oneEnv.code}`, $event)"
                  >
                    <v-icon
                        left
                        dark
                    >
                      mdi-cogs
                    </v-icon>
                    {{ stats[ oneEnv.code ].custom }} Setting{{ stats[ oneEnv.code ].custom > 1 ? 's' : '' }}
                  </v-btn>

                </template>
                <span>Open Settings in {{ oneEnv.code.toUpperCase() }} Environment</span>
              </v-tooltip>
            </v-col>

            <v-col class="pa-0 mb-3" cols="6" md="6" lg="6" xl="3" sm="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="purple lighten-1 white--text"
                      class="white--text"
                      :style="{'width': '90%'}"
                      @click="switchAndGo(oneEnv.code, `/tenants/${oneEnv.code}`, $event)"
                  >
                    <v-icon
                        left
                        class="mr-1"
                    >
                      mdi-apps
                    </v-icon>
                    {{ stats[ oneEnv.code ].tenants }} Application{{ stats[ oneEnv.code ].tenants > 1 ? 's' : '' }}
                  </v-btn>

                </template>
                <span>Open Tenant Applications in {{ oneEnv.code.toUpperCase() }} Environment</span>
              </v-tooltip>
            </v-col>

          </v-row>


          <v-divider></v-divider>

          <div v-if="!loading && oneEnv && oneEnv.code && stats && stats[oneEnv.code]">
            <v-icon
                class="float-right ml-0 pl-0 mr-5 mt-2"
                color="red"
                style="z-index: 1000"
                @click="switchAndGo(oneEnv.code, `/api-catalog/${oneEnv.code}`, $event)"
            >mdi-api
            </v-icon>


            <apexchart type="radialBar" :options="stats[oneEnv.code].services.options"
                       :series="stats[oneEnv.code].services.data.series"></apexchart>
            <!--<chartist-->
            <!--class="pie"-->
            <!--:data="stats[oneEnv.code].services.data"-->
            <!--:options="stats[oneEnv.code].services.options"-->
            <!--type="Pie"-->
            <!--style="max-height: 250px;"-->
            <!--/>-->

            <v-divider class="ma-5"/>

            <v-row
                align="center"
                class="ma-0"
            >
              <v-col cols="6">
                <v-avatar
                    class="mr-1"
                    color="success"
                    size="12"
                />
                <span class="mr-3 font-weight-light">Healthy : {{ stats[ oneEnv.code ].services.data.info[ 0 ] }}</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-avatar
                    class="mr-1"
                    color="red"
                    size="12"
                />
                <span class="mr-3 font-weight-light">Unhealthy : {{
                    stats[ oneEnv.code ].services.data.info[ 1 ]
                  }}</span>
              </v-col>
            </v-row>
          </div>
          <template v-else>
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
          </template>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";

import VueApexCharts from 'vue-apexcharts'

// ?service=consoleapi&v=1&method=get&route=/env
export default {
  name: 'DashboardDashboard',

  mixins: [ globalMixins ],

  components: {
    apexchart: VueApexCharts
  },

  props: {
    envSelected: {
      type: Object
    },
    environments: {
      type: Array
    }
  },

  data: () => ( {
    stats: {},
    interval: null,
    blank: false,
    loading: false,
    timeIntervals: [
      {
        id: 5,
        label: '5 Seconds'
      },
      {
        id: 15,
        label: '15 Seconds'
      },
      {
        id: 30,
        label: '30 Seconds'
      },
      {
        id: 60,
        label: '1 minute'
      },
      {
        id: 300,
        label: '5 minutes'
      }
    ],
    defaultInterval: 30
  } ),

  mounted() {
    const self = this;
    self.loading = true;
    self.clearMessages();

    setTimeout(() => {
      self.refreshStats().then( () => {
        self.loading = false;
      } );
    }, 500);

    // set to run every 30 seconds
    self.interval = setInterval( self.refreshStats.bind( self ), self.defaultInterval * 1000 );
  },

  beforeDestroy() {
    clearInterval( this.interval );
  },

  methods: {

    restartInterval() {
      clearInterval( this.interval );
      this.interval = setInterval( this.refreshStats.bind( this ), this.defaultInterval * 1000 );

    },

    siwtchInterval( value ) {
      this.defaultInterval = value;
      this.restartInterval();
    },

    async refreshStats() {
      const self = this;
      if ( self.environments && self.environments.length > 0 ) {
        const newStats = {};
        const promises = [];
        for ( let i = 0; i < Object.keys( self.environments ).length; i++ ) {
          promises.push( self.getDashboardStats( self.environments[ i ].code ).then( res => {
            newStats[ self.environments[ i ].code ] = res;
          } ).catch( err => {
            console.error( err );
          } ) );
        }
        return Promise.all( promises ).then( () => {
          self.stats = newStats;
        } );
      }
      else {
        self.blank = true;
      }
      return Promise.resolve();
    },

    async getDashboardStats( oneEnvironmentCode ) {
      let database = 0;
      let dataCluster = 0;
      let tenants = 0;
      let customRegistry = 0;
      let awarenessPercentage = 0;
      const stats = await this.getSendData( {
                                              url: `/consoleapi/environments/${ oneEnvironmentCode }/dashboard/stats`,
                                              method: "post",
                                              params: {
                                                entities: [ 'database', 'dataCluster', 'tenants', 'customRegistry', 'awarenessPercentage' ]
                                              }
                                            } );
      if ( stats && stats.item ) {
        database = stats.item.database;
        dataCluster = stats.item.dataCluster;
        tenants = stats.item.tenants;
        customRegistry = stats.item.customRegistry;
        awarenessPercentage = stats.item.awarenessPercentage;
      }

      let success = awarenessPercentage || 0;
      success = Math.round(success, 2);
      const fail = 100 - success;
      return {
        databases: database,
        resources: dataCluster,
        tenants: tenants,
        custom: customRegistry,
        services: {
          data: {
            info: [ success, fail ],
            series: [ success ],
          },
          options: {
            chart: {
              type: 'radialBar',
              offsetY: -20,
              sparkline: {
                enabled: true
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#F44336",
                  strokeWidth: '97%',
                  margin: 1
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    offsetY: -2,
                    fontSize: '22px'
                  }
                }
              }
            },
            grid: {
              padding: {
                top: -10
              }
            },
            fill: {
              colors: [ "#66BB6A" ]
            }
          },
        }
      };
    },

    async switchAndGo( env, loc, $event ) {

      const environment = await this.getSendData( {
                                                    url: `/consoleapi/environments/${ env }`,
                                                    method: "get",
                                                  } );

      this.$emit( 'switchEnv', environment.item.registry );
      this.$store.dispatch( 'auth/setACL', {
        env: environment.item.registry.code.toLowerCase(),
        acl: environment.item.acl
      } );
      this.$forceUpdate();

      this.$router.push( { path: loc } );
      $event.preventDefault();
    }
  },
}
</script>

<style lang="sass">
.pie
  .ct-series-a .ct-slice-pie
    fill: #4caf50 !important

  .ct-series-b .ct-slice-pie
    fill: #ff5252 !important
</style>